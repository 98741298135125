import { isNonEmptyString, isString } from './checks'
import {
  TERMS_WITH_PRECEDING_ARTICLES,
  TERMS_WITHOUT_PRECEDING_ARTICLES
} from '../domain/term/constants'

export const addArticleTo = (word: string): string => {
  if (!isNonEmptyString(word)) {
    return 'a'
  }

  const vowels = ['a', 'e', 'i', 'o', 'u']
  const firstLetter = word.trim().charAt(0).toLowerCase()

  return vowels.includes(firstLetter) ? 'an ' + word : 'a ' + word
}

export const getPrecedingArticle = (title: string): string => {
  if (TERMS_WITH_PRECEDING_ARTICLES.includes(title.toLowerCase())) {
    return 'a'
  }
  if (TERMS_WITHOUT_PRECEDING_ARTICLES.includes(title.toLowerCase())) {
    return ''
  }
  return 'a'
}

export const removeByRegex =
  (regex: RegExp) =>
  (str: string): string => {
    if (isString(str)) {
      return str.replace(regex, '')
    }
    return ''
  }

export const stripNonNumeric = removeByRegex(/[^0-9]+/g)
export const stripSlash = removeByRegex(/\//g)
