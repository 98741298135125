import { Location } from '@/types/models/Location.model'
import { FeedOptions, Kevel_Zone_Ids } from './Feed.model'
import { RefinementList } from '@/domain/search/types'
import { ThirdPartyReview } from './ThirdPartyReview.model'
import { FAQ } from './Faq.model'
import { PrimaryFocus } from '@/domain/primaryFocus/constant'

export type SponsorTier =
  | 1
  | 2
  | 3
  | 101
  | 102
  | 103
  | 201
  | 202
  | 203
  | 1000
  | 0
  | null

export type CenterData = {
  id: string
  _geoloc: {
    lat: string
    lng: string
  }
  occupancy: string
  email: string
  logo: string
  location_label: string
  claimed: boolean
  listing_template: string
  insurance_description: null | string
  verify_insurance_link: null | string
  address: string
  formattedAddress: string
  budget_pacing?: number | null
  card_size: 'Premium' | 'Standard'
  title: string
  featured_image: string
  slug: string
  accepted_insurance: null | string
  short_description?: string
  site: SITE[]
  currency: null | string
  what_we_treat: string[]
  onsite_amenities: string[]
  sponsor_tier: SponsorTier
  slides: string[]
  arranged_aftercare: string[]
  verified_center: string
  verified_owner: string
  length_label: string
  pricing_label: string
  header_image: string
  featured_locations: string[]
  highlights: string[]
  languages: string[]
  locations: string[]
  new_specialization: string[]
  new_specializations: string[]
  personal_amenities: string[]
  phone_numbers: { number: string }[]
  price: string[]
  setting: string[]
  special_considerations: string[]
  provider_type: ProviderType
  status: string
  review_average: number
  review_count: number
  qualityScore?: number
  quality_score?: number
  certifications: string[]
  providerType: ProviderType | ProviderType[]
  primaryFocus: PrimaryFocus
  term_descriptions: { term_title: string; description: string }[]
  accredidation: null | string
  tp_review: {
    provider: string
    platform: string
    review_count: number
    rating: number
  }[]
  treatment_method: string[]
  objectID: string
  _highlightResult: {
    title: { value: string; matchLevel: string; matchedWords: string[] }
    what_we_treat: {
      value: string
      matchLevel: string
      matchedWords: string[]
    }[]
    new_specializations: {
      value: string
      matchLevel: string
      matchedWords: string[]
    }[]
  }
  new_locations: Location[]
  text_picture: unknown[]
  claimed_email?: string
  onsite_activities: string[]
  off_site_activities: string[]
  price_length: string[]
  treatment_therapies: string[]
  aftercare: string[]
  included_aftercare: string[]
  who_we_treat: string[]
  off_amenities: string[]
  treatment_services: string[]
  _new_specializations: string[]
  _locations: Location[]
  descriptions: {
    short: string
    full: string
  }
  _third_party_reviews: ThirdPartyReview[]
  faqs: FAQ[]
  br: Float32Array
  googlePlaceID?: string
}

export type Review = {
  node: {
    center: CenterData['slug'] | string
    rating: number
    review: string
    date: string
    id: string | number
  }
  edges?: Review[]
}

export interface Center extends CenterData {
  score?: number
  position?: number
  sorted?: boolean
  priority?: number
  highlighted_specializations?: string[]
  review?: Review[]
  fromBrowseSearch?: boolean
  __position?: number
  _rankingInfo: {
    filters: number
    geoDistance: number
  }
}

export interface CenterFeedPlacement extends Center {
  feedType: FeedOptions
  feedPosition: number
  zoneId?: string | Kevel_Zone_Ids
  currentRefinements?: Partial<RefinementList>
  placedInFeed?: FeedOptions
}

export type CenterFeed = CenterFeedPlacement

export type SITE = 'lux' | 'rp' | 'rpuk' | 'samhsa'

export enum ProviderType {
  DETOX = 'Detox',
  INTERVENTIONIST = 'Interventionist',
  RECOVERY_COACH = 'Recovery Coach',
  SOBER = 'Sober',
  LIVING = 'Living',
  RETREAT = 'Retreat',
  VIRTUAL = 'Virtual',
  IN_HOME = 'In-Home',
  SOBER_LIVING = 'Sober Living'
}

export type CenterAlgolia = CenterData & {
  ad?: string
  _rankingInfo?: {
    geoDistance: string | number
    filters?: number
    matchedGeoLocation?: { distance: string | number }
  }
  analytics_dashboard?: string
  clickUrl?: string
  impressionUrl?: string
}
